import RecordPlugin from 'wavesurfer.js/plugins/record'

export default class AudioRecorder {

  constructor(audioPlayer, { onRecord, onPause, onProgress, onStop }) {
    const recorder = audioPlayer
      .waveSurfer
      .registerPlugin(new RecordPlugin({
        scrollingWaveform: true,
        scrollingWaveformWindow: 5,
      }))

    recorder.on('record-start', onRecord)
    recorder.on('record-resume', onRecord)
    recorder.on('record-pause', () => {
      onPause()

      // When pausing, and later resuming, the next progress event will be too far in the future (it includes the paused duration).
      // Note that `record-resume` is emitted after `record-progress` which is why we fix this at `record-pause`.
      this.ignoreNextProgress = true
    })
    recorder.on('record-progress', (milliseconds) => {
      if (this.ignoreNextProgress) {
        this.ignoreNextProgress = false
      } else {
        onProgress(milliseconds)
      }
    })
    recorder.on('record-end', (...args) => {
      // The "record-end" event is emitted when destroying.
      // We don't want to call onStop handlers in that case.
      this.recorder && onStop(...args)
    })

    this.recorder = recorder
  }

  destroy() {
    this.recorder.destroy()
    this.recorder = undefined
  }

  async record() {
    const permissions = await this.maybeGetMicrophonePermission()
    const state = permissions?.state

    if (state === 'denied') {
      alert('Leider können wir nicht auf Dein Mikrofon zugreifen. Bitte ändere die Einstellungen oder schreibe uns eine Textnachricht.')
      return
    } else if (state === 'prompt') {
      alert('Damit wir Deine Sprachnachricht aufnehmen können, brauchen wir Zugriff auf Dein Mikrofon.')
      // Permission dialog appears when recording starts.
    }

    this.ensureRecordingDevicePresent().catch(() => {
      alert('Leider können wir kein Aufnahmegerät finden. Bitte schließe ein Mikrofon an oder schreibe uns eine Textnachricht!')
    })

    try {
      if (this.recorder.isPaused()) {
        this.recorder.resumeRecording()
      } else {
        await this.recorder.startRecording()
      }
    } catch {
      if (!state || state === 'granted') {
        alert('Leider funktioniert das Aufnehmen nicht. Schreibe uns bitte eine Textnachricht!')
      }
    }
  }

  async maybeGetMicrophonePermission() {
    // Firefox does not expose "microphone" and instead rejects with an error.
    return navigator.permissions?.query({ name: 'microphone' })?.catch(up.util.noop)
  }

  async ensureRecordingDevicePresent() {
    const expectedDeviceKind = 'audioinput'
    const devices = await navigator.mediaDevices.enumerateDevices()

    if (devices.some(device => device.kind === expectedDeviceKind)) {
      return true
    } else {
      throw new Error(`no ${expectedDeviceKind} device`)
    }
  }

  pause() {
    this.recorder.pauseRecording()
  }

  stop() {
    this.recorder.stopRecording()
  }

  isRecording() {
    return this.recorder.isRecording()
  }

  pendingRecording() {
    return this.recorder.isRecording() || this.recorder.isPaused()
  }

}
