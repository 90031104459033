import getDeviceType from '../util/device_type'

up.compiler('body', async (element) => {
  let debounceTimeout
  const debounceDelay = 100

  function update() {
    element.dataset.deviceType = getDeviceType()
  }

  function debouncedUpdate() {
    clearTimeout(debounceTimeout)
    debounceTimeout = setTimeout(update, debounceDelay)
  }

  const resizeObserver = new ResizeObserver(debouncedUpdate)
  resizeObserver.observe(element)

  up.destructor(element, () => {
    resizeObserver.disconnect()
  })

})
