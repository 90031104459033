export function formatSecondsAsTime(totalSeconds) {
  const seconds = Math.floor(totalSeconds % 60)
  const minutes = Math.floor(totalSeconds / 60)

  return [minutes, seconds]
    .map(v => String(v).padStart(2, '0'))
    .join(':')
}

export function formatMillisecondsAsTime(milliseconds) {
  return formatSecondsAsTime(milliseconds / 1000)
}
