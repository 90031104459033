export default class Duration {
  constructor(seconds) {
    this.seconds = seconds
    this.minutes = this.seconds / 60
    this.hours = this.minutes / 60
    this.days = this.hours / 24
    this.years = this.days / 365
  }

  humanized(locale = 'de') {
    const humanizer = new Duration.Humanizer(locale)

    if (this.minutes < 1) {
      return humanizer.humanize(this.seconds, 'second')
    } else if (this.hours < 1) {
      return humanizer.humanize(this.minutes, 'minute')
    } else if (this.days < 1) {
      return humanizer.humanize(this.hours, 'hour')
    } else if (this.years < 1) {
      return humanizer.humanize(this.days, 'day')
    } else {
      return humanizer.humanize(this.years, 'year')
    }
  }

  static Humanizer = class {
    constructor(locale) {
      this.locale = locale
    }

    humanize(number, unit) {
      const formatOptions = {
        style: 'unit',
        unit,
        unitDisplay: 'long',
      }
      return new Intl.NumberFormat(this.locale, formatOptions).format(Math.round(number))
    }
  }
}
