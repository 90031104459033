import WaveSurfer from 'wavesurfer.js'

export default class AudioPlayer {

  static get EVENTS() {
    return {
      LOADING: 'app:audio-player:loading',
      LOADED: 'app:audio-player:loaded',
      UPDATE_TIME: 'app:audio-player:update-time',
      RESET: 'app:audio-player:reset',
    }
  }

  constructor(container, { src, onPlay, onPause, onStop }) {
    const styles = window.getComputedStyle(container)
    const primaryColor = styles.getPropertyValue('--primary') || styles.getPropertyValue('--bs-primary')
    const currentColor = styles.color

    const waveSurfer = new WaveSurfer({
      container,
      dragToSeek: true,
      cursorColor: primaryColor,
      cursorWidth: 2,
      waveColor: currentColor,
      barWidth: 2,
      barRadius: 10,
      barGap: 4,
      barHeight: 2,
      height: 16,
    })

    this.container = container
    this.waveSurfer = waveSurfer

    waveSurfer.on('play', onPlay)
    waveSurfer.on('pause', onPause)
    waveSurfer.on('finish', onStop)

    waveSurfer.on('finish', () => { this.seekTo(0) })

    if (src) {
      this.onLoading(0)
      waveSurfer.on('loading', this.onLoading.bind(this))
      waveSurfer.on('ready', this.onLoaded.bind(this))
      waveSurfer.load(src)
    } else {
      this.onLoaded(0)
    }
  }

  destroy() {
    this.waveSurfer.destroy()
    this.waveSurfer = undefined
  }

  async play() {
    await this.waveSurfer.play()
  }

  pause() {
    this.waveSurfer.pause()
  }

  stop() {
    this.waveSurfer.stop()
  }

  seekTo(time) {
    this.waveSurfer.seekTo(time)
  }

  setPlaybackRate(rate) {
    this.waveSurfer.setPlaybackRate(rate)
  }

  getPlaybackRate() {
    return this.waveSurfer.getPlaybackRate()
  }

  onLoading(percent) {
    up.emit(this.container, AudioPlayer.EVENTS.LOADING, { percent })
  }

  onLoaded(seconds) {
    up.emit(this.container, AudioPlayer.EVENTS.LOADED)
    up.emit(this.container, AudioPlayer.EVENTS.UPDATE_TIME, { milliseconds: seconds * 1000 })
  }

}
