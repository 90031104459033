import AudioPlayer from '../util/audio_player'
import AudioRecorder from '../util/audio_recorder'
import Duration from '../util/duration'

up.compiler('.audio-recorder', (element, { confirmRemove, maxSeconds }) => {
  const audioPlayerElement = element.querySelector('.audio-player')
  if (!audioPlayerElement) throw new Error('.audio-recorder must contain .audio-player')

  const recordButton = element.querySelector('.audio-recorder--record')
  const pauseButton = element.querySelector('.audio-recorder--pause')
  const removeButton = element.querySelector('.audio-recorder--remove')

  const fileField = element.querySelector('input[type="file"]')
  const form = fileField.closest('form')

  const intersectionObserver = new IntersectionObserver(onIntersectionChange, { root: null })

  const maxMilliseconds = maxSeconds && parseInt(maxSeconds) * 1000

  let recorder
  let submitPending = false

  function init() {
    intersectionObserver.observe(element)
    initializeRecorder()

    recordButton.addEventListener('click', _ => recorder.record())
    pauseButton.addEventListener('click', _ => recorder.pause())
    removeButton.addEventListener('click', onClickRemove)

    form?.addEventListener('app:submit:before', onBeforeSubmit)

    // allow accessing recorder in tests
    element.getRecorderInstance = () => { return recorder }
  }

  function initializeRecorder() {
    const playerInstance = audioPlayerElement.getPlayerInstance()
    if (!playerInstance) throw new Error('player not initialized')

    recorder = new AudioRecorder(playerInstance, { onRecord, onPause, onProgress, onStop })
    setState('empty')
  }

  function destroyRecorder() {
    recorder?.destroy()
    recorder = undefined
  }

  function onClickRemove() {
    if (confirm(confirmRemove)) reset()
  }

  function reset() {
    destroyRecorder()
    up.emit(audioPlayerElement, AudioPlayer.EVENTS.RESET)
    initializeRecorder()
  }

  function setState(state) {
    element.dataset.state = state
  }

  function onIntersectionChange() {
    if (!up.element.isVisible(element)) {
      // Element became invisible. If we are recording, we want to pause.
      recorder.pause()
    }
  }

  function onBeforeSubmit(beforeSubmitEvent) {
    // A recording's blob is only accessible at the stop event, so we stop recording
    // to assign our recorded audio to the file field.
    // The onStop handler will submit again after updating the file field.
    if (recorder.pendingRecording()) {
      submitPending = true
      up.event.halt(beforeSubmitEvent)
      recorder.stop()
    } else {
      setState('submitting')
      submitPending = false
    }
  }

  function onRecord() {
    setState('recording')
    fileField.classList.remove('is-invalid')
  }

  function onPause() {
    setState('paused')
  }

  function onProgress(milliseconds) {
    up.emit(audioPlayerElement, AudioPlayer.EVENTS.UPDATE_TIME, { milliseconds })

    handleMaxMillisecondsExceeded(milliseconds)
  }

  function handleMaxMillisecondsExceeded(milliseconds) {
    if (!maxMilliseconds) return

    if (milliseconds >= maxMilliseconds && recorder.isRecording()) {
      recorder.pause()

      const duration = new Duration(maxMilliseconds / 1000)
      const durationHtml = `<span style="white-space: nowrap">${duration.humanized()}</span>`

      up.layer.open({
        mode: 'modal',
        size: 'auto',
        content: up.element.createFromHTML(`
          <div>
            <h3>Aufnahme beendet</h3>

            <div style="margin-block: 1.5em; max-width: 45ch; text-wrap: pretty;">
              <p>Sprachnachrichten können maximal ${durationHtml} lang sein.</p>
              <p>Du kannst eine neue Aufnahme starten und versuchen, Dich kürzer zu fassen.</p>
              <p>Falls Du in ${durationHtml} nicht alles erzählen kannst, <span style="white-space: nowrap">schreib uns</span> doch eine Textnachricht.</p>
            </div>

            <div style="text-align: right">
              <button up-dismiss type="button" class="btn btn-primary">Verstanden</button>
            </div>
          </div>
        `),
      })
    }
  }

  function onStop(blob) {
    attachRecordingToInputField(blob)

    if (submitPending && form) {
      submitAgain()
    }
  }

  function attachRecordingToInputField(blob) {
    const dataTransfer = new DataTransfer()

    if (blob) {
      const file = new File([blob], 'audio.webm')
      dataTransfer.items.add(file)
    }

    fileField.files = dataTransfer.files
  }

  function submitAgain() {
    if (form.requestSubmit) {
      form.requestSubmit()
    } else {
      // Safari <16
      form.querySelector('[type="submit"]').click()
    }
  }

  init()

  return function onDestroy() {
    intersectionObserver.disconnect()
    form?.removeEventListener('app:submit:before', onBeforeSubmit)
    destroyRecorder()
  }
})
