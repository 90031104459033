up.compiler('.audio-player-playback-rate', (element, { selector }) => {

  const playerElement = document.querySelector(selector)
  const field = element.querySelector('input')

  function init() {
    field.addEventListener('input', onInput)
    field.value = getPlayer().getPlaybackRate()
    updateLabel()
  }

  function onInput() {
    const rate = parseFloat(field.value)
    getPlayer().setPlaybackRate(rate)
    updateLabel()
  }

  function updateLabel() {
    element.dataset.value = parseFloat(field.value).toFixed(2)
  }

  function getPlayer() {
    return playerElement.getPlayerInstance()
  }

  init()

})
