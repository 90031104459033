up.compiler('.plea-form', (element) => {

  const formFieldsSelector = 'input:not([type="hidden"]), textarea, select'

  const fieldsContainer = element.querySelector('.plea-form--fields')
  let fieldsContainerHeight

  const stepFields = [...fieldsContainer.querySelectorAll('[data-step-index]')]

  const submittedStepIndexField = element.querySelector('input[name*="submitted_step_index"]')
  const submittedStepIndex = submittedStepIndexField.value.length > 0 ? parseInt(submittedStepIndexField.value) : -1

  const resizeObserver = new ResizeObserver(onResize)

  let visibleStepIndex

  function init() {
    resizeObserver.observe(fieldsContainer)

    showVisibleStep()
    element.classList.add('-initialized')
    element.addEventListener('submit', onSubmit)

    if (visibleStepIndex > 0) {
      scrollMessages()
      element.classList.add('-interacted')
    } else {
      up.on(element, 'input', { once: true }, () => {
        element.classList.add('-interacted')
      })
    }
  }

  function showVisibleStep() {
    const visibleStepFields = stepFields.find((stepFields) => {
      return hasError(stepFields) || isEmpty(stepFields)
    })
    visibleStepIndex = visibleStepFields ? parseInt(visibleStepFields.dataset.stepIndex) : submittedStepIndex + 1

    stepFields.forEach((step, index) => {
      if (index > submittedStepIndex) {
        removeErrors(step)
      }

      step.hidden = index !== visibleStepIndex
    })

    element.classList.toggle('-completed', stepFields.every(step => step.hidden))

    element.dataset.currentStepIndex = visibleStepIndex
    const firstField = visibleStepFields?.querySelector(formFieldsSelector)

    if (firstField || visibleStepFields) {
      up.util.task(() => {
        up.focus(firstField || visibleStepFields, { force: true, preventScroll: true })
      })
    }
  }

  function onResize(entries) {
    for (const entry of entries) {
      const { height } = entry.contentRect

      if (height !== fieldsContainerHeight) {
        if (fieldsContainerHeight !== undefined) scrollMessages()
        fieldsContainerHeight = height
        break
      }
    }
  }

  function scrollMessages() {
    up.util.task(() => {
      element.scrollIntoView({ block: 'end', behavior: 'smooth' })
    })
  }

  function onSubmit() {
    submittedStepIndexField.value = visibleStepIndex
  }

  function isEmpty(step) {
    const fields = [...step.querySelectorAll(formFieldsSelector)]
    return !fields.find((field) => {
      if (field.type === 'checkbox' && field.value) {
        return field.checked
      } else {
        return field.value.length > 0
      }
    })
  }

  function hasError(step) {
    return !!step.querySelector('.is-invalid')
  }

  function removeErrors(step) {
    step.querySelectorAll('.is-invalid').forEach((invalidElement) => {
      invalidElement.classList.remove('is-invalid')
    })
    step.querySelectorAll('.invalid-feedback.d-block').forEach((invalidElement) => {
      invalidElement.classList.remove('d-block')
    })
  }

  init()

  return function onDestroy() {
    resizeObserver.disconnect()
  }

})
