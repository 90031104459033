import AudioPlayer from '../util/audio_player'
import { formatMillisecondsAsTime } from '../util/time'

up.compiler('.audio-player', { priority: 1 }, (element, { src }) => {
  const playButton = element.querySelector('.audio-player--play')
  const pauseButton = element.querySelector('.audio-player--pause')
  const waveform = element.querySelector('.audio-player--waveform')
  const time = element.querySelector('.audio-player--time')

  let player

  function init() {
    element.addEventListener(AudioPlayer.EVENTS.LOADING, onLoading)
    element.addEventListener(AudioPlayer.EVENTS.UPDATE_TIME, onUpdateTime)
    element.addEventListener(AudioPlayer.EVENTS.RESET, reset)

    initializePlayer()
    playButton.addEventListener('click', _ => player.play())
    pauseButton.addEventListener('click', _ => player.pause())

    // Allow .audio-recorder to access our player instance
    element.getPlayerInstance = () => { return player }
  }

  function initializePlayer() {
    player = new AudioPlayer(waveform, {
      src,
      onPlay: _ => setState('playing'),
      onPause: _ => setState('stopped'),
      onStop: _ => setState('stopped'),
    })

    setState('stopped')
  }

  function destroyPlayer() {
    player?.destroy()
    player = undefined
  }

  function reset() {
    destroyPlayer()
    initializePlayer()
  }

  function setState(state) {
    element.dataset.state = state
  }

  function onLoading({ percent }) {
    percent = Math.min(percent, 100) // sometimes Infinity
    time.textContent = `${Math.round(percent)}%`
  }

  function onUpdateTime({ milliseconds = 0 }) {
    time.textContent = formatMillisecondsAsTime(milliseconds)
  }

  init()

  return function onDestroy() {
    destroyPlayer()
  }
})
