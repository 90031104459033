up.compiler('[submit-change-after-confirm]', (element, { message }) => {

  function onChange(_event) {
    if (!confirm(message)) {
      element.value = element.dataset.previousValue
    } else {
      element.dataset.previousValue = element.value
      up.submit(element)
    }
  }

  element.dataset.previousValue = element.value
  up.on(element, 'change', onChange)
})
