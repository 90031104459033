up.compiler('.code-field', (element) => {

  const groupsOf = 4
  const placeholderLength = 8
  const placeholderCharacter = '_'
  const regExp = new RegExp(`.{1,${groupsOf}}`, 'g')

  function init() {
    element.placeholder = format(''.padEnd(placeholderLength, placeholderCharacter))
    element.addEventListener('input', onInput)
  }

  function format(value) {
    const groups = value.replace(/\s+/g, '').match(regExp) || []
    return groups.join(' ')
  }

  function onInput() {
    const cursorFromRight = element.value.length - element.selectionEnd
    element.value = format(element.value)

    const cursorAt = element.value.length - cursorFromRight
    element.setSelectionRange(cursorAt, cursorAt)
  }

  init()

})
