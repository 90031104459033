import environment from '../util/environment'

up.layer.config.overlay.dismissAriaLabel = 'Schließen'

up.layer.config.modal.dismissable = 'button key' // do not allow closing modals by clicking the backdrop

up.layer.config.modal.openAnimation = 'modal-open'
up.layer.config.modal.closeAnimation = 'modal-close'

const modalClosedStyles = { opacity: 0, transform: 'translateY(-2rem)' }
const modalOpenedStyles = { opacity: 1, transform: 'translateY(0)' }

up.animation('modal-open', function(element, options) {
  up.element.setStyle(element, modalClosedStyles)
  return up.animate(element, modalOpenedStyles, options)
})

up.animation('modal-close', function(element, options) {
  up.element.setStyle(element, modalOpenedStyles)
  return up.animate(element, modalClosedStyles, options)
})

up.link.config.followSelectors.push('a[href]')
up.link.config.instantSelectors.push('a[href]')
up.link.config.noInstantSelectors.push('.btn:not(.btn-link), [no-instant]')

up.fragment.config.runScripts = false

up.feedback.config.currentClasses.push('active')

up.form.config.submitSelectors.push('form')

up.history.config.updateMetaTags = true

up.motion.config.enabled = !environment.isTest

up.network.config.progressBar = true

up.on('up:network:late', () => { document.body.classList.add('-loading') })
up.on('up:network:recover', () => { document.body.classList.remove('-loading') })

up.on('up:request:offline', () => { alert('Du bist offline!\n\nBitte versuche es nochmal, wenn du wieder online bist.') })
