const invalidClass = 'is-invalid'
const selector = ['input', 'textarea', 'select', '.radio-group']
  .map(target => `${target}.${invalidClass}`)
  .join(', ')

up.compiler(selector, (element) => {
  const inputGroup = element.closest('.input-group')
  const formGroup = element.closest('.form-group')

  function setInputGroupInvalid(isInvalid) {
    inputGroup?.classList?.toggle(invalidClass, isInvalid)
  }

  function unsetOtherFormGroupFieldsInvalid() {
    if (formGroup) {
      Array.from(formGroup.querySelectorAll('.is-invalid')).forEach((element) => {
        element.classList.remove(invalidClass)
      })
      formGroup.querySelector('.invalid-feedback.d-block')?.classList?.remove('d-block')
    }
  }

  function unsetInvalid() {
    element.classList.remove(invalidClass)
    unsetOtherFormGroupFieldsInvalid()
    setInputGroupInvalid(false)
  }

  setInputGroupInvalid(true)
  element.addEventListener('input', unsetInvalid, { once: true })
})
