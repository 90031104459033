import environment from '../../shared/util/environment'

export function trackPageView({ status, path }) {
  const hostname = String(location.hostname)
  const wrapperData = {
    et_et: window.analyticsAccountCode,
    et_pagename: `${hostname}${path}`,
    et_areas: `${hostname}/Status ${status}`,
  }

  if (environment.isDevelopment) {
    console.debug('trackPageView', wrapperData)
  }
  if (environment.isTest) {
    window.trackedPageViews ??= []
    window.trackedPageViews.push(wrapperData)
  }

  function sendEvent() {
    window.et_eC_Wrapper(wrapperData)
  }

  if (typeof _etracker === 'object') {
    sendEvent()
  } else {
    window._etrackerOnReady.push(sendEvent)
  }
}
