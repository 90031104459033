import * as ActiveStorage from '@rails/activestorage'

// ActiveStorage hooks into "submit" events and uploads files of form fields
// with [data-direct-upload-url] before the form is "actually" submitted.
//
// For that to work, ActiveStorage uses a capture listener which prevents the
// `submit` event, uploads any files from a form's file fiels to storage,
// and then re-submits the form.
// This is why it integrates with e.g. Unpoly forms without any configuration.
//
// However, it also means we lose the ability to add files to FileLists of such
// fields before form submission, as they provide no events to hook into.
// An example for when this is necessary is audio recording: We must first
// stop recording and then attach the audio blob to the FileList of an input.
// If ActiveStorage is called before us, the audio recording is not yet attached
// and thus not uploaded by ActiveStorage.
//
// Here is our solution to work around that:
// We add a capture listener before ActiveStorage and emit an event that other
// code can listen to. If that `preventDefault` is called on our event, we also
// call `stopImmediatePropagation` on the submit event's capture chain, and
// ActiveStorage's listener will not be called.

function captureSubmit(submitEvent) {
  const captureEvent = new CustomEvent('app:submit:before', {
    bubbles: true,
    cancelable: true,
  })

  submitEvent.target.dispatchEvent(captureEvent)

  if (captureEvent.defaultPrevented) {
    submitEvent.stopImmediatePropagation()
    submitEvent.preventDefault()
  }
}

document.addEventListener('submit', captureSubmit, { capture: true })
ActiveStorage.start()
